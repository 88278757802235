.heroImg {
  width: 100%;
}
.gardenImg {
  max-width: 100%;
  image-rendering: -webkit-optimize-contrast;
  padding: 1em 0;
}
@media screen and(max-width:675px) {
  .gardenImg {
    width: 100%;
  }
}
.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
  opacity: 1;
}